<template>
  <div class="edrea_tm_section" id="about">
    <div class="section_inner">
      <div class="edrea_tm_about">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/1-1.jpg" alt="" />
            <div class="main" data-img-url="img/about/1.jpg"></div>
          </div>
        </div>
        <div class="right">
          <div class="short">
            <h3 class="name">Okan <span class="coloring">ŞAHİN</span></h3>
            <h3 class="job">
              <AnimationText />
            </h3>
          </div>
          <div class="text">
            <p>
              <span>Merhaba.</span> Web tasarımı ve programlama ile uğraşıyorum. 
              Tanıtım siteleri, E-ticaret siteleri gibi sitelerinizi sizin için tasarlıyorum. 
              SEO optimizasyonu ve site bakımlarını yapıyorum. 
              Siteniz ve sosyal medyanız için ihtiyaç duyduğunuz içerikleri(Video, fotoğraf, animasyon) hazırlıyorum.
              Trendyol ve Hepsiburda gibi pazar yerleri için, ürün görsellerinin çekimi ve ürünlerin yüklenmesini yapıyorum. 
              Grafik tasrımı alanında logo,banner, afiş, slider çalışmaları yapıyorum.
            </p>
          </div>
          <div class="edrea_tm_button" id="aboutPopup">
            <a href="#">Bana Ulaşın</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimationText from "./AnimationText.vue";
export default {
  name: `AboutComponent`,
  data() {
    return {
      open: false,
    };
  },
  components: {
    AnimationText,
  },
};
</script>

<style scoped>
.edrea_tm_modalbox {
  top: -90px;
}
</style>
