<template>
  <div class="edrea_tm_section" id="news">
    <div class="section_inner">
      <div class="edrea_tm_news swiper-section">
        <div class="edrea_tm_main_title">
          <h3>Bazı <span class="coloring">Haberler</span></h3>
        </div>
      <div class="news_list">
          <swiper
            :loop="false"
            :slidesPerView="1"
            :spaceBetween="0"
            :loopAdditionalSlides="1"
            :autoplay="{
              delay: 6000,
            }"
            :navigation="{
              nextEl: '.my_next',
              prevEl: '.my_prev',
            }"
            :pagination="pagination"
            :breakpoints="{
              700: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }"
            @slideChange="onSlideChange"
            :modules="modules"
            class="swiper-container"
          >
            <swiper-slide
              class="swiper-slide"
              v-for="(data, i) in newsData"
              :key="i"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-3.jpg" alt="" />
                  <div class="main" :data-img-url="data.img"></div>
                  <a class="edrea_tm_full_link news_popup" href="#"></a>
                </div>
                <div class="details">
                  <h3>
                    <a href="#">{{ data.title }}</a>
                  </h3>
                  <span
                    ><a href="#">{{ data.tag }}</a></span
                  >
                </div>

                <!--News Popup Informations-->
                <div class="edrea_tm_hidden_content">
                  <div class="news_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div class="main" :data-img-url="data.img"></div>
                    </div>
                    <div class="news_main_title">
                      <h3>{{ data.title }}</h3>
                      <span
                        ><a href="#">{{ data.tag }}</a></span
                      >
                      <div></div>
                    </div>
                    <div class="text">
                      <p v-for="(text, t) in data.des" :key="t">
                        {{ text }}
                      </p>
                    </div>
                  </div>
                  
                </div>
                
                <!--News Popup Informations-->
              </div>
            </swiper-slide>
            <div class="edrea_tm_swiper_progress fill">
              <div class="my_pagination_in">
                <span class="currentNews">0</span>
                <span class="pagination_progress"
                  ><span class="all allNews"><span></span></span
                ></span>
                <span class="totalNews">04</span>
              </div>
              <div class="my_navigation">
                <ul>
                  <li>
                    <a class="my_prev" href="#"
                      ><i class="icon-left-open-1"></i
                    ></a>
                  </li>
                  <li>
                    <a class="my_next" href="#"
                      ><i class="icon-right-open-1"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperSliderCustomSlider } from "../utilits";

export default {
  name: `NewsComponent`,
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSlideChange(swiper) {
      this.activeSlider = swiper.activeIndex + 1;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".edrea_tm_swiper_progress",
        type: "custom", // progressbar
        renderCustom: function (swiper, current, total) {
          swiperSliderCustomSlider(
            current,
            total,
            "currentNews",
            "totalNews",
            "allNews"
          );
        },
      },
    };
  },
  data() {
    return {
      newsData: [
        {
          title: "OpenAI, ChatGPT'nin yeni sürümü GPT-4o'yu tanıttı, Her(Aşk) filmi gerçek oldu",
          img: "img/news/1.jpg",
          tag: "Yapay Zeka",
          des: [
            "Yapay zeka sohbet robotu ChatGPT'nin geliştiricisi OpenAI, düzenlediği konferansta yapay zeka botuna yeni bir güncelleme getirdi. GPT-4o güncellemesiyle birlikte yapay zekanın sesli sohbetlerde gerçek bir insan gibi diyalog kurabildiği, çeviri yapabildiği ve metin ile görsel arasında etkileşim kurabildiği görülürken, akıllara Joaquin Phoenix'in başrol oynadığı Her filmi geldi."
          ,"  "
          ],
        },
        {
          title: "Microsoft’tan son çağrı! Bu Windows 10 sürümü tarihe karışıyor",
          img: "img/news/2.jpg",
          tag: "Yazılım",
          des: [
            "Microsoft, 21H2 sürümünün 11 Haziran’dan sonra desteklenmeyeceğini açıkladı. Bu tarihten itibaren 21H2 çalıştıran sistemlere güncelleme verilmeyecek. Microsoft tarafından sağlanan güvenlik güncellemeleri, sistem açıklarına karşı korumayı bırakacak.",
            "  ",
          ],
        },
        {
          title: "Microsoft Edge özelliği GPT-4 Desteği ile ışık hızına ulaşacak!",
          img: "img/news/3.jpg",
          tag: "Yapay Zeka",
          des: [
            "Microsoft, Edge tarayıcısının otomatik doldurma özelliğine büyük bir güncelleme getiriyor. Şirket, bu özelliği yapay zeka teknolojisiyle harmanlayarak kullanıcıların daha karmaşık formları dahi rahatça doldurabilmesini sağlayacak. Bu, özellikle online formlarla sık sık uğraşanlar için adeta hayat kurtarıcı olacak.",
            "  ",
            ,
          ],
        },
        {
          title: "Sonunda, iPhonelar için beklenen Chrome tarayıcısı özelliği geliyor mu?",
          img: "img/news/4.jpg",
          tag: "Teknoloji",
          des: [
            "Google nihayet beklenen çoklu profil bir özelliğini iPhone için Chrome tarayıcısına getiriyor. Uzun zamandır Safari’de bulunan bu özellik, artık Chrome için de geliyor, böylece aynı cihazı paylaşan kullanıcılar kendi yer imlerini, geçmişlerini ve arama verilerini ayrı ayrı tutabilecekler.",
            ," ",
          ],
        },
        {
          title: "Unreal Engine 5.4 sürümü oyun geliştiricilerinin kullanımına sunuldu",
          img: "img/news/5.jpg",
          tag: "Yazılım",
          des: [
            "Unreal Engine 5.4, oyuncuları yakından ilgilendiren gelişmiş performans özellikleriyle dikkat çekiyor. Mevcut nesil konsollar ve PC’lerde daha akıcı oyun deneyimleri sağlayarak 60 FPS hedefini daha erişilebilir hale getirmeyi amaçlıyor. Epic Games, yeni sürümde gölgelendirici derlemesi üzerinde önemli iyileştirmeler yaptıklarını ve oyunlardaki performansı artırdıklarını belirtiyor.",
            " ",
          ],
        },
        {
          title: "Google Chrome değişti. Google Chrome, yapay zeka robotu Gemini nasıl kullanılır?",
          img: "img/news/6.jpg",
          tag: "Modern",
          des: [
            "Gemini özelliği, kullanıcıların doğrudan adres çubuğundan hızlı bir şekilde bilgi alışverişinde bulunmalarını sağlayan bir sohbet aracı olarak karşımıza çıkıyor. Yani, işlerinizi hızlandırmak için artık bir sayfa açıp arama yapmanıza gerek kalmayacak. Adres çubuğuna @gemini yazıp bir boşluk bıraktıktan sonra sorunuzu ekleyin ve enter tuşuna basın.",
            " ",
          ],
        },
      ],
    };
  },
};
</script>
